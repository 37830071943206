import React from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo.png';

const FooterContainer = styled.footer`
  background-color: #f5f5f7;
  color: #6e6e73;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.33337;
  font-weight: 400;
  letter-spacing: -.01em;
  min-width: 320px;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
`;

const FooterContent = styled.div`
  margin: 0 auto;
  max-width: 980px;
  padding: 0 22px;
  padding-left: max(22px, env(safe-area-inset-left));
  padding-right: max(22px, env(safe-area-inset-right));
`;

const FooterNote = styled.div`
  border-bottom: 1px solid #d2d2d7;
  color: #86868b;
  padding: 17px 0 11px;
  
  p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
`;

const FooterDirectory = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 30px;
  
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const DirectoryColumn = styled.div``;

const ColumnHeader = styled.h3`
  color: #1d1d1f;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33337;
  letter-spacing: -.01em;
  margin: 0 0 0.8em;
`;

const ColumnList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ColumnItem = styled.li`
  margin-bottom: 0.8em;
  letter-spacing: 0;
  &:hover {
    color: #1d1d1f;
  }
`;

const FooterMiniShop = styled.div`
  border-bottom: 1px solid #d2d2d7;
  margin-top: 34px;
  padding-bottom: 21px;
  
  @media only screen and (max-width: 767px) {
    margin-top: 20px;
  }
`;

const FooterLegal = styled.div`
  padding: 17px 0 21px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const LegalLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 7px 30px;
`;

const LegalLink = styled.span`
  color: #424245;
  &:hover {
    color: #1d1d1f;
  }
`;

const Copyright = styled.div`
  color: #86868b;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterNote>
          <p>Medical Universe는 의료 통역 서비스의 새로운 기준을 제시합니다.</p>
          <p>다국어 지원과 AI 통역으로 언어의 장벽을 넘어서세요.</p>
        </FooterNote>

        <FooterDirectory>
          <DirectoryColumn>
            <ColumnHeader>회사 정보</ColumnHeader>
            <ColumnList>
              <ColumnItem>주식회사 메디컬 유니버스</ColumnItem>
              <ColumnItem>대표명: 이승재</ColumnItem>
              <ColumnItem>사업자번호: 809-86-03067</ColumnItem>
            </ColumnList>
          </DirectoryColumn>

          <DirectoryColumn>
            <ColumnHeader>연락처</ColumnHeader>
            <ColumnList>
              <ColumnItem>인천 연수구 센트럴로 313,</ColumnItem>
              <ColumnItem>송도씨워크인테라스한라 씨동 625호-38호</ColumnItem>
              <ColumnItem>medicalu2024@medicaluniverse.kr</ColumnItem>
            </ColumnList>
          </DirectoryColumn>

          <DirectoryColumn>
            <ColumnHeader>서비스</ColumnHeader>
            <ColumnList>
              <ColumnItem>INTER MEDIC</ColumnItem>
              <ColumnItem>의료 통역 서비스</ColumnItem>
              <ColumnItem>다국어 지원</ColumnItem>
            </ColumnList>
          </DirectoryColumn>
        </FooterDirectory>

        <FooterMiniShop />

        <FooterLegal>
          <LegalLinks>
            <LegalLink>개인정보 처리방침</LegalLink>
            <LegalLink>웹 사이트 이용약관</LegalLink>
            <LegalLink>판매 및 환불</LegalLink>
            <LegalLink>법적 고지</LegalLink>
            <LegalLink>사이트 맵</LegalLink>
          </LegalLinks>
          <Copyright>Copyright © 2024 Medical Universe Inc. All rights reserved.</Copyright>
        </FooterLegal>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer; 