import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
`;

const StatCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const StatTitle = styled.div`
  color: #86868b;
  font-size: 14px;
  margin-bottom: 8px;
`;

const StatValue = styled.div`
  color: #1d1d1f;
  font-size: 24px;
  font-weight: 600;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
`;

const ChartContainer = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const ActivityFeed = styled(ChartContainer)``;

const ChartTitle = styled.h2`
  font-size: 18px;
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActivityItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e1e1e1;
  
  &:last-child {
    border-bottom: none;
  }
`;

const ActivityText = styled.div`
  color: #1d1d1f;
`;
const ActivityTime = styled.div`
  color: #86868b;
  font-size: 14px;
`;

const DashboardMain = () => {
  const stats = [
    { title: '전체 환자 수', value: '1,234' },
    { title: '금일 예약', value: '28' },
    { title: '대기 문의', value: '15' },
    { title: '통역 서비스 이용', value: '892' }
  ];

  const activities = [
    { text: '새로운 환자 예약이 등록되었습니다.', time: '5분 전' },
    { text: '통역 서비스가 요청되었습니다.', time: '15분 전' },
    { text: '진료 기록이 업데이트되었습니다.', time: '30분 전' },
    { text: '새로운 문의가 접수되었습니다.', time: '1시간 전' },
    { text: '예약 일정이 변경되었습니다.', time: '2시간 전' }
  ];

  return (
    <Container>
      <StatsGrid>
        {stats.map((stat, index) => (
          <StatCard key={index}>
            <StatTitle>{stat.title}</StatTitle>
            <StatValue>{stat.value}</StatValue>
          </StatCard>
        ))}
      </StatsGrid>

      <ContentGrid>
        <ChartContainer>
          <ChartTitle>진료 통계</ChartTitle>
          <div style={{ 
            height: '300px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            color: '#86868b'
          }}>
            차트가 들어갈 영역입니다
          </div>
        </ChartContainer>

        <ActivityFeed>
          <ChartTitle>최근 활동</ChartTitle>
          <ActivityList>
            {activities.map((activity, index) => (
              <ActivityItem key={index}>
                <ActivityText>{activity.text}</ActivityText>
                <ActivityTime>{activity.time}</ActivityTime>
              </ActivityItem>
            ))}
          </ActivityList>
        </ActivityFeed>
      </ContentGrid>
    </Container>
  );
};

export default DashboardMain; 