import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  background: #fff;
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 120px 0 60px;
  background: linear-gradient(180deg, #fbfbfd 0%, #fff 100%);
`;

const HeroTitle = styled.h1`
  font-size: 56px;
  line-height: 1.07143;
  font-weight: 600;
  letter-spacing: -.005em;
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const HeroSubtitle = styled.p`
  font-size: 28px;
  line-height: 1.10722;
  font-weight: 400;
  letter-spacing: .004em;
  color: #86868b;
  margin: 0 auto;
  max-width: 600px;
`;

const DownloadSection = styled.section`
  padding: 100px 0;
`;

const ContentWrapper = styled.div`
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;
`;

const DownloadGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 60px 0;
  
  @media (max-width: 834px) {
    grid-template-columns: 1fr;
  }
`;

const DownloadCard = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  
  &:hover {
    transform: translateY(-6px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
  }
`;

const PlatformIcon = styled.div`
  font-size: 48px;
  color: #1d1d1f;
  margin-bottom: 20px;
  font-weight: 600;
`;

const PlatformTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 12px;
`;

const PlatformVersion = styled.p`
  font-size: 15px;
  color: #86868b;
  margin-bottom: 24px;
`;

const DownloadButton = styled.button`
  background: #0071e3;
  color: #fff;
  border: none;
  border-radius: 980px;
  padding: 12px 24px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: #0077ED;
  }
`;

const SystemRequirements = styled.div`
  font-size: 13px;
  color: #86868b;
  margin-top: 20px;
  line-height: 1.4;
`;

const DownloadPage = () => {
  const platforms = [
    {
      icon: "PC",
      title: "Windows PC",
      version: "v2.5.0",
      requirements: "Windows 10 이상",
      downloadUrl: "#",
      size: "84.5 MB"
    },
    {
      icon: "iOS",
      title: "iOS",
      version: "v3.0.2",
      requirements: "iOS 14.0 이상",
      downloadUrl: "#",
      size: "125.8 MB"
    },
    {
      icon: "Android",
      title: "Android",
      version: "v3.0.2",
      requirements: "Android 8.0 이상",
      downloadUrl: "#",
      size: "95.2 MB"
    }
  ];

  return (
    <PageContainer>
      <HeroSection>
        <ContentWrapper>
          <HeroTitle>다운로드</HeroTitle>
          <HeroSubtitle>
            모든 기기에서 INTER MEDIC을 경험하세요
          </HeroSubtitle>
        </ContentWrapper>
      </HeroSection>

      <DownloadSection>
        <ContentWrapper>
          <DownloadGrid>
            {platforms.map((platform, index) => (
              <DownloadCard key={index}>
                <PlatformIcon>{platform.icon}</PlatformIcon>
                <PlatformTitle>{platform.title}</PlatformTitle>
                <PlatformVersion>
                  {platform.version} • {platform.size}
                </PlatformVersion>
                <DownloadButton>
                  다운로드
                </DownloadButton>
                <SystemRequirements>
                  시스템 요구사항: {platform.requirements}
                </SystemRequirements>
              </DownloadCard>
            ))}
          </DownloadGrid>
        </ContentWrapper>
      </DownloadSection>
    </PageContainer>
  );
};

export default DownloadPage; 