import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  font-family: "SF Pro KR", "SF Pro Text", "SF Pro Icons", -apple-system, BlinkMacSystemFont, "Noto Sans KR", "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

const HeroSection = styled.section`
  height: 100vh;
  background: #000;
  color: #f5f5f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 44px;
`;

const HeroTitle = styled.h1`
  font-size: 56px;
  line-height: 1.07143;
  font-weight: 600;
  letter-spacing: -.005em;
  margin-bottom: 20px;
`;

const HeroSubtitle = styled.h2`
  font-size: 28px;
  line-height: 1.10722;
  font-weight: 400;
  letter-spacing: .004em;
  color: #86868b;
`;

const Section = styled.section`
  padding: 100px 0;
  background: ${props => props.dark ? '#000' : '#fff'};
  color: ${props => props.dark ? '#f5f5f7' : '#1d1d1f'};
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  text-align: center;
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 60px 0;
  
  @media (max-width: 834px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 734px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background: ${props => props.dark ? '#1d1d1f' : '#f5f5f7'};
  border-radius: 18px;
  padding: 40px;
  text-align: center;
  
  h3 {
    font-size: 24px;
    line-height: 1.16667;
    font-weight: 600;
    letter-spacing: .009em;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -.022em;
    color: #86868b;
  }
`;

const SectionTitle = styled.h2`
  font-size: 48px;
  line-height: 1.08349;
  font-weight: 600;
  letter-spacing: -.003em;
  margin-bottom: 20px;
  color: #1d1d1f;
`;

const SectionText = styled.p`
  font-size: 21px;
  line-height: 1.381;
  font-weight: 400;
  letter-spacing: .011em;
  color: #86868b;
  max-width: 800px;
  margin: 0 auto 40px;
`;

const VideoSection = styled.section`
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #000;
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }
`;

const VideoContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #f5f5f7;
  width: 100%;
  padding: 0 20px;
`;

const LanguageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin: 60px auto;
  max-width: 1200px;
  
  @media (max-width: 1068px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (max-width: 734px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(120deg, #FF6B6B, #4ECDC4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const LanguageCard = styled.div`
  background: #fff;
  border-radius: 18px;
  padding: 30px 20px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #e5e5e5;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, 
      ${props => props.$bgColor}10, 
      ${props => props.$bgColor}20
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  
  &:hover {
    transform: translateY(-6px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
    border-color: ${props => props.$bgColor}40;
    
    &::before {
      opacity: 1;
    }
    
    h4 {
      transform: scale(1.05);
    }
  }
`;

const LanguageIcon = styled.div`
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 15px;
  background: linear-gradient(135deg, ${props => props.$bgColor}, ${props => props.$bgColor}99);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.5px;
`;

const LanguageName = styled.h4`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  letter-spacing: -0.5px;
  transition: transform 0.4s ease;
  position: relative;
  z-index: 1;
  color: ${props => props.$bgColor};
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  
  ${props => props.$bgColor === '#FFCC00' && `
    color: #1d1d1f;
  `}
`;

const LanguageDetails = styled.div`
  font-size: 14px;
  color: #636e72;
  line-height: 1.6;
  text-align: left;
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
`;

const HeroDescription = styled.p`
  font-size: 21px;
  line-height: 1.381;
  font-weight: 400;
  letter-spacing: .011em;
  color: #86868b;
  margin-top: 20px;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 60px 0;
  
  @media (max-width: 834px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 734px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureIcon = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
  color: ${props => props.color};
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 16px;
  color: #1d1d1f;
`;

const FeatureDescription = styled.p`
  font-size: 17px;
  line-height: 1.47059;
  color: #86868b;
`;

const ServiceSection = styled.section`
  padding: 100px 0;
  background: ${props => props.dark ? '#000' : '#fff'};
  color: ${props => props.dark ? '#f5f5f7' : '#1d1d1f'};
`;

const ServiceCard = styled.div`
  background: ${props => props.dark ? '#1d1d1f' : '#f5f5f7'};
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 30px;
  
  h3 {
    font-size: 28px;
    margin-bottom: 20px;
    color: ${props => props.color};
  }
  
  p {
    font-size: 17px;
    line-height: 1.6;
    color: #86868b;
    margin-bottom: 15px;
  }
`;

const ServiceList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
  
  li {
    font-size: 16px;
    line-height: 1.8;
    color: #86868b;
    padding-left: 24px;
    position: relative;
    
    &:before {
      content: "•";
      color: ${props => props.color};
      position: absolute;
      left: 0;
    }
  }
`;

const AIFeatureSection = styled.section`
  padding: 120px 0;
  background: #fbfbfd;
`;

const AIFeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 80px auto;
  max-width: 1200px;
  padding: 0 30px;
  
  @media (max-width: 1068px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 734px) {
    grid-template-columns: 1fr;
  }
`;

const AIFeatureCard = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }
`;

const AIFeatureIcon = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
`;

const AIFeatureTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
`;

const AIFeatureDescription = styled.p`
  font-size: 17px;
  line-height: 1.5;
  color: #86868b;
  margin-bottom: 20px;
`;

const AIFeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  
  li {
    font-size: 15px;
    color: #1d1d1f;
    margin-bottom: 12px;
    padding-left: 24px;
    position: relative;
    
    &:before {
      content: "•";
      color: #06c;
      position: absolute;
      left: 0;
    }
  }
`;

const CompanyPage = () => {
  const languages = [
    ['영어', '#147CE5'],
    ['프랑스어', '#007AFF'],
    ['독일어', '#5856D6'],
    ['스페인어', '#1AB2E8'],
    ['네덜란드어', '#2CD158'],
    ['러시아어', '#FF2D55'],
    ['중국어', '#FF3B30'],
    ['일본어', '#FF9500'],
    ['한국어', '#1D1D1F'],
    ['아랍어', '#AF52DE'],
    ['히브리어', '#32ADE6'],
    ['힌디어', '#30B0C7']
  ];

  const services = [
    {
      title: '의료 전문 용어 데이터베이스',
      color: '#FF6B6B',
      description: '50만 건 이상의 의료 전문 용어와 임상 데이터를 기반으로 정확한 의료 통역을 제공합니다.',
      features: [
        '질환명, 증상, 처방약 등 전문 의료 용어 DB 구축',
        '의료 분야별 특화 용어 지원 (내과, 외과, 정형외과 등)',
        '실시간 용어 업데이트 및 확장',
        '의료진 감수를 통한 신뢰성 확보',
        '각국 의료 제도에 맞춘 용어 현지화'
      ]
    },
    {
      title: '실시간 음성 통역',
      color: '#4ECDC4',
      description: '첨단 AI 기술을 활용한 실시간 음성 인식으로 자연스러운 의사소통을 지원합니다.',
      features: [
        '0.3초 이내 초고속 응답 시간',
        '의료 상황별 맞춤 통역 제공',
        '방언 및 억양 인식 기능',
        '주변 소음 제거 기술 적용',
        '응급 상황 우선 처리 시스템'
      ]
    },
    {
      title: 'OCR 문서 번역',
      color: '#45B7D1',
      description: '처방전, 진단서, 의무기록 등 모든 의료 문서를 즉시 번역하여 제공합니다.',
      features: [
        '99.9% 정확도의 문자 인식',
        '의료 문서 양식 자동 인식',
        '도장 및 서명 보존 처리',
        '개인정보 보호 암호화',
        '번역 문서 진위 확인 시스템'
      ]
    }
  ];

  const aiFeatures = [
    {
      icon: '🤖',
      title: '실시간 AI 통역',
      description: '의료진과 환자 간의 원활한 의사소통을 지원합니다.',
      features: [
        '24시간 실시간 음성 통역',
        '의료 전문 용어 자동 변환',
        '방언 및 억양 인식',
        '감정 분석을 통한 공감 통역',
        '응급 상황 우선 처리'
      ]
    },
    {
      icon: '🏥',
      title: '스마트 진료 지원',
      description: '진료 전 과정을 디지털화하여 효율적인 의료 서비스를 제공합니다.',
      features: [
        'AI 기반 예진 시스템',
        '증상 분석 및 진료과 추천',
        '진료 기록 자동 번역',
        '처방전 다국어 변환',
        '투약 알림 서비스'
      ]
    },
    {
      icon: '📱',
      title: '모바일 의료 서비스',
      description: '스마트폰으로 언제 어디서나 의료 서비스를 이용할 수 있습니다.',
      features: [
        '모바일 진료 예약',
        'QR코드 접수 시스템',
        '대기 시간 실시간 알림',
        '비대면 상담 지원',
        '모바일 결제 시스템'
      ]
    },
    {
      icon: '🔍',
      title: 'AI 영상 분석',
      description: '의료 영상을 AI가 분석하여 정확한 진단을 지원합니다.',
      features: [
        'X-ray 이미지 분석',
        'MRI 스캔 데이터 해석',
        '병변 자동 감지',
        '3D 이미지 렌더링',
        '진단 보조 리포트 생성'
      ]
    },
    {
      icon: '📋',
      title: '의무기록 관리',
      description: '환자의 의료 기록을 체계적으로 관리하고 번역합니다.',
      features: [
        '다국어 의무기록 변환',
        '의료 문서 자동 분류',
        '개인정보 보호 암호화',
        '클라우드 백업 시스템',
        '의료 기록 이력 추적'
      ]
    },
    {
      icon: '🌐',
      title: '글로벌 의료 네트워크',
      description: '전 세계 의료 기관과의 협력을 지원합니다.',
      features: [
        '해외 의료기관 연계',
        '원격 의료 상담',
        '의료 관광 지원',
        '국제 의료 인증 관리',
        '글로벌 의료 데이터 공유'
      ]
    }
  ];

  return (
    <PageContainer>
      <HeroSection>
        <GradientOverlay />
        <HeroTitle>
          Medical Universe
          <GradientText>AI 의료 통역의 미래</GradientText>
        </HeroTitle>
        <HeroSubtitle>
          세계를 연결하는 의료 통역 솔루션
        </HeroSubtitle>
        <HeroDescription>
          11개국 언어 지원으로 전 세계 환자들과 자유롭게 소통하세요
        </HeroDescription>
      </HeroSection>

      <Section>
        <ContentWrapper>
          <SectionTitle>
            <GradientText>혁신적인 의료 통역 기술</GradientText>
          </SectionTitle>
          <SectionText>
            메디컬 유니버스는 최첨단 AI 기술을 활용하여 의료 현장의 언어 장벽을 해소합니다.
            실시간 음성 통역부터 의료 문서 번역까지, 모든 의료 커뮤니케이션을 지원합니다.
          </SectionText>
          <FeatureGrid>
            {services.map((service, index) => (
              <ServiceCard key={index} dark color={service.color}>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <ServiceList color={service.color}>
                  {service.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ServiceList>
              </ServiceCard>
            ))}
          </FeatureGrid>
        </ContentWrapper>
      </Section>

      <Section>
        <ContentWrapper>
          <SectionTitle>
            <GradientText>지원 언어</GradientText>
          </SectionTitle>
          <SectionText>
            전 세계 12개국 언어를 지원하여 더 많은 환자들과 원활한 소통이 가능합니다.
            각 언어별 의료 전문 용어 데이터베이스를 구축하여 정확한 통역을 제공합니다.
          </SectionText>
          <LanguageGrid>
            {languages.map(([name, color]) => (
              <LanguageCard key={name} $bgColor={color}>
                <LanguageName $bgColor={color}>{name}</LanguageName>
              </LanguageCard>
            ))}
          </LanguageGrid>
        </ContentWrapper>
      </Section>

      <AIFeatureSection>
        <ContentWrapper>
          <SectionTitle>
            AI 의료 서비스
          </SectionTitle>
          <SectionText>
            최첨단 AI 기술로 더 나은 의료 서비스를 제공합니다.
            환자와 의료진 모두를 위한 스마트한 솔루션을 경험하세요.
          </SectionText>
          <AIFeatureGrid>
            {aiFeatures.map((feature, index) => (
              <AIFeatureCard key={index}>
                <AIFeatureIcon>{feature.icon}</AIFeatureIcon>
                <AIFeatureTitle>{feature.title}</AIFeatureTitle>
                <AIFeatureDescription>{feature.description}</AIFeatureDescription>
                <AIFeatureList>
                  {feature.features.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </AIFeatureList>
              </AIFeatureCard>
            ))}
          </AIFeatureGrid>
        </ContentWrapper>
      </AIFeatureSection>
    </PageContainer>
  );
};

export default CompanyPage; 