import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 80px; // 헤더 높이만큼 여백
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const HospitalPage = () => {
  return (
    <PageContainer>
      <StyledIframe 
        src="https://www.uvishospital.co.kr/"
        title="현대유비스병원"
        sandbox="allow-same-origin allow-scripts"
      />
    </PageContainer>
  );
};

export default HospitalPage;
