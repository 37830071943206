import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  background: #fff;
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 120px 0 60px;
  background: linear-gradient(180deg, #fbfbfd 0%, #fff 100%);
`;

const HeroTitle = styled.h1`
  font-size: 56px;
  line-height: 1.07143;
  font-weight: 600;
  letter-spacing: -.005em;
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const HeroSubtitle = styled.p`
  font-size: 28px;
  line-height: 1.10722;
  font-weight: 400;
  letter-spacing: .004em;
  color: #86868b;
  margin: 0 auto;
  max-width: 600px;
`;

const ReviewSection = styled.section`
  padding: 100px 0;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const ReviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin: 60px 0;
  
  @media (max-width: 834px) {
    grid-template-columns: 1fr;
  }
`;

const ReviewCard = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-6px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
  }
`;

const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const ReviewInfo = styled.div`
  flex: 1;
`;

const ReviewTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 8px;
`;

const ReviewMeta = styled.p`
  font-size: 15px;
  color: #86868b;
`;

const ReviewContent = styled.p`
  font-size: 17px;
  line-height: 1.47059;
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const ReviewHighlights = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 20px;
`;

const Highlight = styled.span`
  background: #f5f5f7;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  color: #1d1d1f;
`;

const ReviewsPage = () => {
  const reviews = [
    {
      title: "의료 통역 시스템 도입 후 진료 효율 크게 향상",
      type: "종합병원",
      date: "2024년 1분기",
      content: "다국어 환자 진료 시 의사소통이 원활해져 진료 시간이 단축되었으며, 환자 만족도가 크게 향상되었습니다. 특히 전문 의학 용어 번역의 정확도가 인상적입니다.",
      highlights: ["진료 효율 향상", "다국어 지원", "정확한 의학 용어 번역"]
    },
    {
      title: "AI 문서 처리 시스템으로 행정 업무 간소화",
      type: "전문병원",
      date: "2023년 4분기",
      content: "의료 문서의 디지털화와 자동 번역 기능으로 행정 처리 시간이 크게 단축되었습니다. 데이터 관리가 체계적으로 이루어져 업무 효율이 향상되었습니다.",
      highlights: ["업무 효율화", "자동화된 문서 처리", "체계적 데이터 관리"]
    },
    {
      title: "글로벌 진료 네트워크 확장에 큰 도움",
      type: "의료센터",
      date: "2023년 4분기",
      content: "해외 의료기관과의 협력이 원활해졌으며, 글로벌 환자 유치가 증가했습니다. 다국어 지원 시스템으로 국제 진료가 더욱 수월해졌습니다.",
      highlights: ["글로벌 네트워크", "국제 진료", "원활한 의사소통"]
    },
    {
      title: "AI 진단 보조 시스템의 높은 신뢰도",
      type: "전문클리닉",
      date: "2024년 1분기",
      content: "영상 판독 시 AI 분석이 제공하는 인사이트가 매우 유용합니다. 진단의 정확도를 높이는데 큰 도움이 되고 있습니다.",
      highlights: ["정확한 진단", "AI 분석", "의료 품질 향상"]
    }
  ];

  return (
    <PageContainer>
      <HeroSection>
        <ContentWrapper>
          <HeroTitle>고객 후기</HeroTitle>
          <HeroSubtitle>
            의료 AI 솔루션을 통해 경험한 실제 의료 현장의 변화를 확인하세요
          </HeroSubtitle>
        </ContentWrapper>
      </HeroSection>

      <ReviewSection>
        <ContentWrapper>
          <ReviewGrid>
            {reviews.map((review, index) => (
              <ReviewCard key={index}>
                <ReviewHeader>
                  <ReviewInfo>
                    <ReviewTitle>{review.title}</ReviewTitle>
                    <ReviewMeta>{review.type} · {review.date}</ReviewMeta>
                  </ReviewInfo>
                </ReviewHeader>
                <ReviewContent>{review.content}</ReviewContent>
                <ReviewHighlights>
                  {review.highlights.map((highlight, idx) => (
                    <Highlight key={idx}>{highlight}</Highlight>
                  ))}
                </ReviewHighlights>
              </ReviewCard>
            ))}
          </ReviewGrid>
        </ContentWrapper>
      </ReviewSection>
    </PageContainer>
  );
};

export default ReviewsPage; 