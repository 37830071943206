import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  background: #fff;
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 120px 0 60px;
  background: linear-gradient(180deg, #fbfbfd 0%, #fff 100%);
`;

const HeroTitle = styled.h1`
  font-size: 56px;
  line-height: 1.07143;
  font-weight: 600;
  letter-spacing: -.005em;
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const NoticeSection = styled.section`
  padding: 60px 0 120px;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
`;

const NoticeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const NoticeItem = styled.div`
  background: #fff;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid #e5e5e5;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  }
`;

const NoticeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const NoticeTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #1d1d1f;
`;

const NoticeDate = styled.span`
  font-size: 14px;
  color: #86868b;
`;

const NoticeTag = styled.span`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  margin-right: 8px;
  background: ${props => {
    switch (props.type) {
      case '업데이트': return '#e8f2ff';
      case '예정': return '#f5f5f7';
      case '긴급': return '#ffe8e8';
      default: return '#f5f5f7';
    }
  }};
  color: ${props => {
    switch (props.type) {
      case '업데이트': return '#0066cc';
      case '예정': return '#86868b';
      case '긴급': return '#ff3b30';
      default: return '#86868b';
    }
  }};
`;

const NoticeContent = styled.div`
  font-size: 15px;
  line-height: 1.5;
  color: #1d1d1f;
  
  ul {
    list-style: none;
    padding: 0;
    margin: 12px 0;
    
    li {
      padding-left: 20px;
      position: relative;
      margin-bottom: 8px;
      
      &:before {
        content: "•";
        position: absolute;
        left: 0;
        color: #86868b;
      }
    }
  }
`;

const NoticePage = () => {
  const notices = [
    {
      title: "Medical AI Platform v2.5.0 업데이트 예정",
      date: "2025.2.10",
      type: "업데이트",
      content: `의료 AI 플랫폼의 주요 업데이트가 진행되었습니다.`,
      details: [
        "AI 진단 보조 시스템 정확도 향상",
        "실시간 통역 엔진 성능 개선",
        "의료 문서 OCR 인식률 향상",
        "사용자 인터페이스 개선",
        "시스템 안정성 강화"
      ]
    },
    {
      title: "모바일 앱 v3.0 출시 예정 안내",
      date: "2025.7.23",
      type: "예정",
      content: `2025년 7월 중 모바일 앱 메이저 업데이트가 진행될 예정입니다.`,
      details: [
        "iOS/Android 동시 업데이트 예정",
        "새로운 UI/UX 디자인 적용",
        "실시간 알림 시스템 도입",
        "진료 예약 시스템 개선",
        "생체 인증 보안 강화"
      ]
    },
    {
      title: "시스템 정기 점검 안내",
      date: "2024.12.13",
      type: "예정",
      content: `서비스 안정화를 위한 정기 점검 예정.`,
      details: [
        "일시: 2024.12.13 02:00 ~ 06:00 (4시간)",
        "대상: 전체 시스템",
        "내용: 서버 최적화 및 보안 업데이트",
        "영향: 해당 시간 동안 서비스 이용 제한",
        "조치: 자동 백업 및 데이터 동기화"
      ]
    },
    {
      title: "보안 업데이트 패치 적용",
      date: "2025.11.12",
      type: "긴급",
      content: `시스템 보안 강화를 위한 패치 적용 예정.`,
      details: [
        "보안 취약점 패치 적용",
        "암호화 알고리즘 업데이트",
        "개인정보 보호 강화",
        "접근 권한 시스템 개선",
        "로그 모니터링 강화"
      ]
    },
    {
      title: "웹 플랫폼 v4.0 개발 로드맵",
      date: "2025.12.28",
      type: "예정",
      content: `2026년 상반기 출시 예정인 웹 플랫폼 v4.0의 개발 계획을 안내드립니다.`,
      details: [
        "AI 엔진 전면 업그레이드",
        "글로벌 의료 네트워크 확장",
        "실시간 협업 기능 강화",
        "빅데이터 분석 도구 추가",
        "클라우드 인프라 확장"
      ]
    }
  ];

  return (
    <PageContainer>
      <HeroSection>
        <ContentWrapper>
          <HeroTitle>공지사항</HeroTitle>
        </ContentWrapper>
      </HeroSection>

      <NoticeSection>
        <ContentWrapper>
          <NoticeList>
            {notices.map((notice, index) => (
              <NoticeItem key={index}>
                <NoticeHeader>
                  <NoticeTitle>{notice.title}</NoticeTitle>
                  <NoticeDate>{notice.date}</NoticeDate>
                </NoticeHeader>
                <NoticeTag type={notice.type}>{notice.type}</NoticeTag>
                <NoticeContent>
                  <p>{notice.content}</p>
                  <ul>
                    {notice.details.map((detail, idx) => (
                      <li key={idx}>{detail}</li>
                    ))}
                  </ul>
                </NoticeContent>
              </NoticeItem>
            ))}
          </NoticeList>
        </ContentWrapper>
      </NoticeSection>
    </PageContainer>
  );
};

export default NoticePage; 