import React from 'react';
import styled from 'styled-components';

//
// Apple 웹사이트 스타일을 참고한 디자인 가이드
// - 넓은 공백과 심플한 컬러 팔레트 (화이트 및 라이트 그레이)
// - 큰 폰트와 깔끔한 레이아웃
// - 섹션간 명확한 구분
// - 반응형 디자인
//

// 전체 페이지 컨테이너
const PageContainer = styled.div`
  background: #f5f5f7;
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Apple SD Gothic Neo", Arial, sans-serif;
  color: #1d1d1f;
`;

// 메인 타이틀
const Title = styled.h1`
  font-size: 52px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 60px;
  letter-spacing: -0.03em;
`;

// 섹션 컨테이너
const DocSection = styled.section`
  background: #ffffff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  margin-bottom: 60px;

  @media(max-width: 768px) {
    padding: 30px;
  }
`;

// 섹션 제목
const SectionTitle = styled.h2`
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: -0.01em;
`;

// API 상세 박스
const ApiBox = styled.div`
  border-top: 1px solid #d2d2d7;
  padding-top: 30px;
  margin-top: 30px;

  &:first-child {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
`;

// API 이름
const ApiName = styled.h3`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
`;

// API 개요 설명
const ApiDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #515154;
`;

// 메소드, 경로 표시
const ApiMethodPath = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
`;

const HttpMethod = styled.span`
  display: inline-block;
  background: #0070c9;
  color: #ffffff;
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 8px;
  margin-right: 10px;
`;

const ApiPath = styled.span`
  font-weight: 400;
  color: #333333;
`;

// 요청/응답 정보 타이틀
const InfoTitle = styled.h4`
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
`;

// 코드 블록 스타일
const CodeBlock = styled.pre`
  background: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  overflow-x: auto;
  line-height: 1.5;
  color: #333;
`;

// 설명 리스트
const ParamList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
`;

const ParamItem = styled.li`
  font-size: 16px;
  margin-bottom: 10px;

  & > strong {
    display: inline-block;
    min-width: 120px;
    font-weight: 600;
  }
`;

// 섹션 간단 소개 텍스트
const IntroText = styled.p`
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 40px;
  color: #515154;
`;

// 섹션 나누기 선
const Divider = styled.hr`
  border: none;
  border-top: 1px solid #d2d2d7;
  margin: 40px 0;
`;

const ApiDocPage = () => {
  return (
    <PageContainer>
      <Title>INTER MEDIC API 문서</Title>

      <DocSection>
        <SectionTitle>개요</SectionTitle>
        <IntroText>
          본 문서는 INTER MEDIC 서버의 REST API 사용 방법을 설명합니다.  
          인증/인가를 포함한 사용자 관리, 채팅방 생성 및 메시지 전송 기능을 다루는 엔드포인트들의 요청 및 응답 형식, 필드, 예제를 제시합니다.
        </IntroText>

        <InfoTitle>기본 사항</InfoTitle>
        <ParamList>
          <ParamItem><strong>Base URL:</strong> http://yourserver.com</ParamItem>
          <ParamItem><strong>Content-Type:</strong> application/json</ParamItem>
          <ParamItem><strong>Authentication:</strong> Bearer 토큰 (JWT)</ParamItem>
        </ParamList>
      </DocSection>

      <DocSection>
        <SectionTitle>인증 및 사용자 관리</SectionTitle>

        {/* Health Check */}
        <ApiBox>
          <ApiName>서버 상태 확인</ApiName>
          <ApiDescription>서버가 정상적으로 동작하는지 확인합니다.</ApiDescription>
          <ApiMethodPath>
            <HttpMethod>GET</HttpMethod>
            <ApiPath>/health</ApiPath>
          </ApiMethodPath>
          <InfoTitle>성공 응답 예시</InfoTitle>
          <CodeBlock>{`{
  "status": "ok",
  "message": "서버가 정상적으로 실행 중입니다."
}`}</CodeBlock>
        </ApiBox>

        {/* Register */}
        <ApiBox>
          <ApiName>회원가입</ApiName>
          <ApiDescription>새로운 사용자를 등록하고, 해당 사용자 전용의 개인 채팅방을 자동으로 생성합니다.</ApiDescription>
          <ApiMethodPath>
            <HttpMethod>POST</HttpMethod>
            <ApiPath>/api/auth/register</ApiPath>
          </ApiMethodPath>

          <InfoTitle>요청 필드</InfoTitle>
          <ParamList>
            <ParamItem><strong>email:</strong> 사용자 이메일</ParamItem>
            <ParamItem><strong>password:</strong> 비밀번호</ParamItem>
            <ParamItem><strong>name:</strong> 사용자 이름</ParamItem>
            <ParamItem><strong>preferred_language:</strong> 선호 언어(기본값: en)</ParamItem>
            <ParamItem><strong>role_id:</strong> 역할 ID(기본값: 3, 일반 사용자)</ParamItem>
          </ParamList>

          <InfoTitle>요청 예시</InfoTitle>
          <CodeBlock>{`POST /api/auth/register
Content-Type: application/json

{
  "email": "user@example.com",
  "password": "password123",
  "name": "John Doe",
  "preferred_language": "en",
  "role_id": 3
}`}</CodeBlock>

          <InfoTitle>성공 응답 예시</InfoTitle>
          <CodeBlock>{`{
  "success": true,
  "message": "회원가입 성공 및 개인 채팅방 생성 완료",
  "data": {
    "user_id": 1,
    "email": "user@example.com",
    "role_id": 3,
    "preferred_language": "en",
    "room_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
  }
}`}</CodeBlock>
        </ApiBox>

        {/* Login */}
        <ApiBox>
          <ApiName>로그인</ApiName>
          <ApiDescription>이메일과 비밀번호를 통해 인증하고, JWT 토큰을 발급받습니다.</ApiDescription>
          <ApiMethodPath>
            <HttpMethod>POST</HttpMethod>
            <ApiPath>/api/auth/login</ApiPath>
          </ApiMethodPath>

          <InfoTitle>요청 필드</InfoTitle>
          <ParamList>
            <ParamItem><strong>email:</strong> 사용자 이메일</ParamItem>
            <ParamItem><strong>password:</strong> 비밀번호</ParamItem>
          </ParamList>

          <InfoTitle>요청 예시</InfoTitle>
          <CodeBlock>{`POST /api/auth/login
Content-Type: application/json

{
  "email": "user@example.com",
  "password": "password123"
}`}</CodeBlock>

          <InfoTitle>성공 응답 예시</InfoTitle>
          <CodeBlock>{`{
  "success": true,
  "message": "로그인 성공",
  "data": {
    "token": "jwt_token_here",
    "user": {
      "user_id": 1,
      "email": "user@example.com",
      "name": "John Doe",
      "preferred_language": "en",
      "role_id": 3,
      "status": "active",
      "created_at": "2024-01-01T12:00:00.000Z",
      "updated_at": "2024-01-01T12:00:00.000Z",
      "last_login": null
    }
  }
}`}</CodeBlock>
        </ApiBox>

        {/* Logout */}
        <ApiBox>
          <ApiName>로그아웃</ApiName>
          <ApiDescription>현재 인증 세션을 만료시킵니다. (토큰 무효화)</ApiDescription>
          <ApiMethodPath>
            <HttpMethod>POST</HttpMethod>
            <ApiPath>/api/auth/logout</ApiPath>
          </ApiMethodPath>

          <InfoTitle>헤더</InfoTitle>
          <ParamList>
            <ParamItem><strong>Authorization:</strong> Bearer {`<JWT 토큰>`}</ParamItem>
          </ParamList>

          <InfoTitle>성공 응답 예시</InfoTitle>
          <CodeBlock>{`{
  "success": true,
  "message": "로그아웃 성공"
}`}</CodeBlock>
        </ApiBox>
      </DocSection>

      <DocSection>
        <SectionTitle>채팅방 관리</SectionTitle>

        {/* Get Chat Rooms */}
        <ApiBox>
          <ApiName>채팅방 목록 조회</ApiName>
          <ApiDescription>
            사용자의 역할에 따라 조회 범위가 달라집니다.<br />
            - 관리자(ROLE_ID=1,2): 모든 활성화된 채팅방 조회<br />
            - 일반 사용자(ROLE_ID=3): 본인이 참여한 채팅방만 조회
          </ApiDescription>
          <ApiMethodPath>
            <HttpMethod>GET</HttpMethod>
            <ApiPath>/api/chat/rooms</ApiPath>
          </ApiMethodPath>
          <InfoTitle>헤더</InfoTitle>
          <ParamList>
            <ParamItem><strong>Authorization:</strong> Bearer {`<JWT 토큰>`}</ParamItem>
          </ParamList>

          <InfoTitle>성공 응답 예시</InfoTitle>
          <CodeBlock>{`{
  "success": true,
  "data": [
    {
      "room_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
      "room_name": "user@example.com-personal",
      "room_type": "INDIVIDUAL",
      "creator_id": 1,
      "status": "active",
      "created_at": "2024-01-01T12:00:00.000Z",
      "updated_at": null
    }
  ]
}`}</CodeBlock>
        </ApiBox>

        {/* Join Chat Room by Admin */}
        <ApiBox>
          <ApiName>관리자 채팅방 참여</ApiName>
          <ApiDescription>
            관리자가 특정 유저의 개인 채팅방에 참여합니다. 해당 관리자 혹은 스태프만 호출 가능합니다.
          </ApiDescription>
          <ApiMethodPath>
            <HttpMethod>POST</HttpMethod>
            <ApiPath>/api/chat/rooms/join</ApiPath>
          </ApiMethodPath>

          <InfoTitle>헤더</InfoTitle>
          <ParamList>
            <ParamItem><strong>Authorization:</strong> Bearer {`<JWT 토큰>`}</ParamItem>
          </ParamList>

          <InfoTitle>요청 필드</InfoTitle>
          <ParamList>
            <ParamItem><strong>room_id:</strong> 참여할 채팅방 ID</ParamItem>
          </ParamList>

          <InfoTitle>요청 예시</InfoTitle>
          <CodeBlock>{`POST /api/chat/rooms/join
Content-Type: application/json
Authorization: Bearer <JWT 토큰>

{
  "room_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
}`}</CodeBlock>

          <InfoTitle>성공 응답 예시</InfoTitle>
          <CodeBlock>{`{
  "success": true,
  "message": "관리자가 채팅방에 참여했습니다."
}`}</CodeBlock>
        </ApiBox>
      </DocSection>

      <DocSection>
        <SectionTitle>메시지 관리</SectionTitle>

        {/* Send Message */}
        <ApiBox>
          <ApiName>메시지 전송</ApiName>
          <ApiDescription>
            채팅방에 메시지를 전송합니다.  
            메시지는 상대방의 선호 언어로 번역되어 저장됩니다.
          </ApiDescription>
          <ApiMethodPath>
            <HttpMethod>POST</HttpMethod>
            <ApiPath>/api/chat/messages</ApiPath>
          </ApiMethodPath>

          <InfoTitle>헤더</InfoTitle>
          <ParamList>
            <ParamItem><strong>Authorization:</strong> Bearer {`<JWT 토큰>`}</ParamItem>
          </ParamList>

          <InfoTitle>요청 필드</InfoTitle>
          <ParamList>
            <ParamItem><strong>room_id:</strong> 메시지를 전송할 채팅방 ID</ParamItem>
            <ParamItem><strong>message:</strong> 원문 메시지</ParamItem>
          </ParamList>

          <InfoTitle>요청 예시</InfoTitle>
          <CodeBlock>{`POST /api/chat/messages
Content-Type: application/json
Authorization: Bearer <JWT 토큰>

{
  "room_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
  "message": "Hello, how are you?"
}`}</CodeBlock>

          <InfoTitle>성공 응답 예시</InfoTitle>
          <CodeBlock>{`{
  "success": true,
  "message": "메시지 전송 성공",
  "data": {
    "message_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    "room_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    "user_id": 1,
    "original_message": "Hello, how are you?",
    "translated_message": "안녕하세요, 잘 지내시나요?",
    "original_language": "en",
    "translated_language": "ko",
    "created_at": "2024-01-01T12:30:00.000Z"
  }
}`}</CodeBlock>
        </ApiBox>

        {/* Get Messages */}
        <ApiBox>
          <ApiName>메시지 목록 조회</ApiName>
          <ApiDescription>
            특정 채팅방의 모든 메시지를 시간 순으로 조회합니다.
            사용자는 해당 채팅방에 참여하고 있어야 합니다.
          </ApiDescription>
          <ApiMethodPath>
            <HttpMethod>GET</HttpMethod>
            <ApiPath>/api/chat/messages?room_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx</ApiPath>
          </ApiMethodPath>

          <InfoTitle>헤더</InfoTitle>
          <ParamList>
            <ParamItem><strong>Authorization:</strong> Bearer {`<JWT 토큰>`}</ParamItem>
          </ParamList>

          <InfoTitle>쿼리 파라미터</InfoTitle>
          <ParamList>
            <ParamItem><strong>room_id:</strong> 조회할 채팅방 ID</ParamItem>
          </ParamList>

          <InfoTitle>성공 응답 예시</InfoTitle>
          <CodeBlock>{`{
  "success": true,
  "data": [
    {
      "message_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
      "room_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
      "user_id": 1,
      "message": "Hello, how are you?",
      "translated_message": "안녕하세요, 잘 지내시나요?",
      "original_language": "en",
      "translated_language": "ko",
      "created_at": "2024-01-01T12:30:00.000Z"
    }
  ]
}`}</CodeBlock>
        </ApiBox>
      </DocSection>

      <Divider />

      <DocSection>
        <SectionTitle>오류 처리</SectionTitle>
        <IntroText>
          인증 실패, 권한 부족, 잘못된 요청 등의 경우 HTTP 상태코드와 에러 메시지를 반환합니다.
          모든 응답은 JSON 형식입니다.
        </IntroText>

        <InfoTitle>예시: 인증 실패</InfoTitle>
        <CodeBlock>{`{
  "success": false,
  "message": "인증 토큰이 필요합니다."
}`}</CodeBlock>

        <InfoTitle>예시: 권한 부족</InfoTitle>
        <CodeBlock>{`{
  "success": false,
  "message": "관리자만 다른 채팅방에 참여 가능합니다."
}`}</CodeBlock>

        <InfoTitle>예시: 서버 오류</InfoTitle>
        <CodeBlock>{`{
  "success": false,
  "message": "서버 오류가 발생했습니다."
}`}</CodeBlock>
      </DocSection>
    </PageContainer>
  );
};

export default ApiDocPage;
